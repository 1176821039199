@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Emitha-Script';
  src: local('Emitha-Script'), url(./Assets/Fonts/Emitha-Script.otf) format('opentype');
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,200&display=swap');
