h1, h2, h3 {
  font-family: 'Emitha-Script';
  color:#fff;
}

h1 {
  font-size: 6rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 3rem;
}

p {
  font-family: 'Roboto Flex', sans-serif;
  color:#fff
}

.subHeading {
  font-family: 'Roboto Flex', sans-serif;
  color:#fff
}